<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.summaryOfContainersByTypeStatus') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm" 
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"    
                    v-model="search"           
                >
                </CInput>
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getContainerByTypeSummary"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            un: 'TON',
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
            nulo:'', 
            pageSize:'',
            search:'',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }
    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            /*let select = this.$el.querySelector("#unidadMedida");
            select.length = 0;
            select.options.add(new Option("Seleccione", ""));
            for (let i = 0; i < listado.length; i++) {
                select.options.add(new Option(listado[i].UnitMeasureAbbrev, listado[i].UnitMeasureId));
            }

            //select.addEventListener('change', filterUnitMeasureWeight);
            select.addEventListener('change', function() { this.unitMeasureId = select.value; this.getContainerByTypeSummary(); })*/
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }            
            this.unitMeasureId = listado[0].UnitMeasureId;

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            rowData.map(function(item){
                computedItems.push({
                    Description: item.Description,
                    Full: item.Full,
                    Empty: item.Empty,
                    Total: item.Total,
                    Weigth: formatMilDecimal(Number.parseFloat(item.Weigth).toFixed(2)),
                    AvgWeigth: formatMilDecimal(Number.parseFloat(item.AvgWeigth).toFixed(2)),
                })
            })
            await this.getPdf(computedItems,this.$t('label.summaryOfPlannedContainersByTypeStatus'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;  
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.summaryOfPlannedContainersByTypeStatus'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
        this.un = this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label;
        this.getContainerByTypeSummary();
    }

    async function getContainerByTypeSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;
        
        let TypeSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };

        await this.$http.post("StowagePlanningContainerTypeSummary", TypeSumaryJson, { root: 'TypeSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            for (i = 0; i < listado.length; i++) {
                /*if (control) {
                    this.formatedItems.push({
                        Description: "",
                        Full: "",
                        Empty: "",
                        Total: "",
                        Weigth: "",
                        AvgWeigth: ""
                    });
                    control = false;
                }
                
                if (listado[i].Description == "TOTAL") control=true;*/
                this.formatedItems.push({
                    Description: listado[i].Description,
                    Full: listado[i].Full,
                    Empty: listado[i].Empty,
                    Total: listado[i].Total,
                    Weigth: listado[i].Weigth,
                    AvgWeigth: listado[i].AvgWeigth
                });
                this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';

            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    function getRowClass(params) {
        if (params.node.data.Description=="TOTAL") {
            return 'bg-edit font-weight-bold text-white'
        }
    }
    //computed
    function columnDefs(){
        let columnDefs = [
            {
                field: "Description",
                headerName: "",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 120,
                cellClass: 'gb-cell',
                //checkboxSelection: true,
                //pinned: 'left', 
            },
            {
                field: "Full",
                headerName: "FULL",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
            },
            {
                field: "Empty",
                headerName: "EMPTY",
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
            },
            {
                field: "Total",
                headerName: "TOTAL",
                headerClass: 'center-cell-especial gb-cell',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial gb-cell',
                filter: 'agNumberColumnFilter',
                minWidth: 170,
            },
            {
                field: "Weigth",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
                /*headerComponentParams: {
                    template:
                        '<div class="ag-cell-label-container" role="presentation">' +
                        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                        `<label id='unidadm' class='mt-2'>TON</label>`+
                        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                        '  </div>' +                            
                        '</div>'
                }*/
            },
            {
                field: "AvgWeigth",
                headerName: "AVGWEIGHT",
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 170,
                cellClass: 'center-cell-especial',
                filter: 'agNumberColumnFilter',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
        ]

      
        return columnDefs;
    }
    export default {
        name: "container-type-status-summary",
        data,
        beforeMount,
        mounted,
        props: ['value'],
        mixins: [Reportes, AgGrid],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getContainerByTypeSummary,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged
        },
        computed:{
            columnDefs,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                user: state => state.auth.user,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==2) {
                    this.pageSize='';
                    this.unitMeasureId='';
                    this.search=''; 
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getContainerByTypeSummary();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-amarillo{
        background: #fbf4bb;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>